<template lang="pug">
    .row
        .col-md-offset-2.col-md-8(v-show="!loading")
            form.form-horizontal(name="formExpenseAdd" @submit.prevent="submit()")
                .form-group
                    label.col-sm-4.control-label.star-required(for="type") {{'register.expense.current.form.TYPE'|translate}}
                    .col-sm-8
                        OptiSelectEnum#type(enum="ExpensesAccountType"
                            :value="form.type"
                            name="type"
                            @update-enum="changeType")
                        OptiValidate(f="type" :data="$v.form.type")
                .form-group
                    label.col-sm-4.control-label.star-required(for="document") {{'register.expense.current.form.DOCUMENT'|translate}}
                    .col-sm-8
                        OptiSelectEnum#document(enum="ExpensesAccountDocument"
                            :value="form.document"
                            name="document"
                            @update-enum="f => form.document = f")
                        OptiValidate(f="document" :data="$v.form.document")
                .form-group
                    label.col-sm-4.control-label.star-required(for="value") {{'register.expense.current.form.VALUE'|translate}}
                    .col-sm-8
                        OptiNumber#value(
                            :header="'register.expense.current.form.VALUE'|translate"
                            :value="form.value"
                            :float="true"
                            @confirmed="value => form.value = value") {{form.value|currency}}
                        OptiValidate(f="value" :data="$v.form.value")
                .form-group
                    label.col-sm-4.control-label.star-required(for="nodocument") {{'register.expense.current.form.NODOCUMENT'|translate}}
                    .col-sm-8
                        input#nodocument.form-control(v-model="form.nodocument" name="nodocument")
                        OptiValidate(f="nodocument" :data="$v.form.nodocument")
                .form-group
                    label.col-sm-4.control-label.star-required(for="nip") {{'register.expense.current.form.NIP'|translate}}
                    .col-sm-8
                      input#nip.form-control(v-model="form.nip" name="nip")
                      OptiValidate(f="nip" :data="$v.form.nip")
                .form-group
                    label.col-sm-4.control-label.star-required(for="date_of_document") {{'register.expense.current.form.DATEOFDOCUMENT'|translate}}
                    .col-sm-8
                        OptiDate#date_of_document(:value="form.date_of_document" @update-date="d => form.date_of_document = d")
                        OptiValidate(f="date_of_document" :data="$v.form.date_of_document")
                        .bold.warn(v-show="$v.form.date_of_document.$invalid === true") {{'register.expense.current.notify.error.DATE'|translate}}
                .form-group
                    label.col-sm-4.control-label(for="description") {{'register.expense.current.form.DESCRIPTION'|translate}}
                    .col-sm-8
                        textarea#description.form-control(v-model="form.description" name="description" rows="5")
                .form-group
                    .col-sm-8.col-sm-offset-4(v-show="$uac.user.departmentType === 3")
                        label
                            .checkbox.c-checkbox.checkbox-primary.inline
                                label
                                    input(type="checkbox" name="franchise_cost" v-model="form.franchise_cost")
                                    span.fa.fa-check
                            span {{'register.expense.current.form.FRANCHISE_COST'|translate}}
                .form-group
                    .col-sm-8.col-sm-offset-4
                        OptiButtonSave(:disabled="$v.form.$invalid")
        .col-md-offset-2.col-md-8
            OptiDimmer(:active="loading")
</template>
<script>
import {API} from '../../../api'
import {required, maxValue, minLength, maxLength, numeric} from 'vuelidate/lib/validators'
import {noZero} from '@/js/vue.validators.js'

import {validateNIP} from '@/js/app/vue/helpers/NIP';

import OptiSelectEnum from '../../Select/OptiSelectEnum'
import OptiNumber from '../../Inputs/OptiNumber'
import OptiButtonSave from '../../Button/OptiButtonSave'
import OptiValidate from '../../OptiValidate'
import OptiDimmer from '../../Blocks/OptiDimmer'
import OptiDate from '@/js/app/vue/components/OptiDate.vue'

export default {
    name: 'OptiRegisterExpenseCurrentAdd',
    components: {
        OptiDate,
        OptiDimmer,
        OptiValidate,
        OptiButtonSave,
        OptiNumber,
        OptiSelectEnum
    },
    data() {
        return {
            loading: false,
            form: {
                type: 'transport',
                document: 1,   //  invoice VAT
                value: 0,
                nodocument: '',
                description: '',
                franchise_cost: false,
                date_of_document: moment().format('YYYY-MM-DD'),
                nip: ''
            }
        }
    },
    validations: {
        form: {
            type: {
                required
            },
            document: {
                required
            },
            value: {
                noZero
            },
            nodocument: {
                required
            },
            date_of_document: {
                required,
                maxValue(value) {
                    return new Date(value) <= new Date();
                }
            },
            nip: {
              required,
              numeric,
              minLength: minLength(10),
              maxLength: maxLength(10),
              validateNIP(value) {
                if (value.length !== 10) return true;

                return validateNIP(value);
              }
            }
        }
    },
    methods: {
        submit() {
            this.loading = true

            API.post('register/expenses/current/add', this.form).then((res) => {
                if (res.data.status) {
                    this.$state.go('app.register.current')

                    this.$notify.success('register.expense.current.notify.SAVE')
                } else {
                    this.$notify.error('register.expense.current.notify.error.SAVE')
                }

                this.loading = false
            }).finally(() => {
                this.loading = false
            }).catch(() => {
                this.$notify.error('register.expense.current.notify.error.SAVE')
            })
        },
        changeType(type) {
            this.form.type = type
            this.form.document = ''
        }
    }
}
</script>