<template lang="pug">
    .panel.panel-primary(style="position:relative")
        .panel-heading
            i.fa.fa-navicon.mr3
            span {{'settings.schedule.DEPARTMENT'|translate}}
        .panel-body
            form.form-horizontal(name="formScheduleTask" @submit.prevent="scheduleTaskSubmit()")
                .row
                    .col-sm-6
                        .form-group.bold(style="padding-left:15px") {{'settings.schedule.WEEK'|translate}}
                        .form-group
                            label.col-sm-3.control-label(for="week_from_hour") {{'settings.schedule.FROM'|translate}}
                            .col-sm-9
                                .col-xs-5(style="padding:0 0 15px")
                                    select#week_from_hour.form-control(name="week_from_hour" v-model="schedule.week.from.hour")
                                        option(v-for="h in hours") {{h}}
                                .col-xs-2.bold.text-center(style="padding-top:5px") :
                                .col-xs-5(style="padding:0 0 15px")
                                    select#week_from_minute.form-control(name="week_from_minute" v-model="schedule.week.from.minute")
                                        option(v-for="m in minutes") {{m}}
                        .form-group
                            label.col-sm-3.control-label(for="week_to_hour") {{'settings.schedule.TO'|translate}}
                            .col-sm-9
                                .col-xs-5(style="padding:0 0 15px")
                                    select#week_to_hour.form-control(name="week_to_hour" v-model="schedule.week.to.hour")
                                        option(v-for="h in hours") {{h}}
                                .col-xs-2.bold.text-center(style="padding-top:5px") :
                                .col-xs-5(style="padding:0 0 15px")
                                    select#week_to_minute.form-control(name="week_to_minute" v-model="schedule.week.to.minute")
                                        option(v-for="m in minutes") {{m}}
                        .form-group.bold(style="padding-left:15px") {{'settings.schedule.SATURDAY'|translate}}
                        .form-group
                            label.col-sm-3.control-label(for="saturday_from_hour") {{'settings.schedule.FROM'|translate}}
                            .col-sm-9
                                .col-xs-5(style="padding:0 0 15px")
                                    select#saturday_from_hour.form-control(name="saturday_from_hour" v-model="schedule.saturday.from.hour")
                                        option(v-for="h in hours") {{h}}
                                .col-xs-2.bold.text-center(style="padding-top:5px") :
                                .col-xs-5(style="padding:0 0 15px")
                                    select#saturday_from_minute.form-control(name="saturday_from_minute" v-model="schedule.saturday.from.minute")
                                        option(v-for="m in minutes") {{m}}
                        .form-group
                            label.col-sm-3.control-label(for="saturday_to_hour") {{'settings.schedule.TO'|translate}}
                            .col-sm-9
                                .col-xs-5(style="padding:0 0 15px")
                                    select#saturday_to_hour.form-control(name="saturday_to_hour" v-model="schedule.saturday.to.hour")
                                        option(v-for="h in hours") {{h}}
                                .col-xs-2.bold.text-center(style="padding-top:5px") :
                                .col-xs-5(style="padding:0 0 15px")
                                    select#saturday_to_minute.form-control(name="saturday_to_minute" v-model="schedule.saturday.to.minute")
                                        option(v-for="m in minutes") {{m}}
                    .col-sm-6
                        .form-group.bold(style="padding-left:15px") {{'settings.schedule.SUNDAY'|translate}}
                        .form-group
                            label.col-sm-3.control-label(for="sunday_from_hour") {{'settings.schedule.FROM'|translate}}
                            .col-sm-9
                                .col-xs-5(style="padding:0 0 15px")
                                    select#sunday_from_hour.form-control(name="sunday_from_hour" v-model="schedule.sunday.from.hour")
                                        option(v-for="h in hours") {{h}}
                                .col-xs-2.bold.text-center(style="padding-top:5px") :
                                .col-xs-5(style="padding:0 0 15px")
                                    select#sunday_from_minute.form-control(name="sunday_from_minute" v-model="schedule.sunday.from.minute")
                                        option(v-for="m in minutes") {{m}}
                        .form-group
                            label.col-sm-3.control-label(for="sunday_to_hour") {{'settings.schedule.TO'|translate}}
                            .col-sm-9
                                .col-xs-5(style="padding:0 0 15px")
                                    select#sunday_to_hour.form-control(name="sunday_to_hour" v-model="schedule.sunday.to.hour")
                                        option(v-for="h in hours") {{h}}
                                .col-xs-2.bold.text-center(style="padding-top:5px") :
                                .col-xs-5(style="padding:0 0 15px")
                                    select#sunday_to_minute.form-control(name="sunday_to_minute" v-model="schedule.sunday.to.minute")
                                        option(v-for="m in minutes") {{m}}
                        .form-group
                                .col-sm-9.col-sm-offset-3
                                    OptiButtonSave(type="submit")

        OptiDimmer(:active="loadingSchedule")
</template>

<script>
import {API} from '@/js/app/vue/api'

import OptiButtonSave from '@/js/app/vue/components/Button/OptiButtonSave'
import OptiDimmer from '@/js/app/vue/components/Blocks/OptiDimmer'

    export default {
        name: 'OptiSettingsSchedule',
        components: {OptiButtonSave, OptiDimmer},
        data() {
            return {
                loadingSchedule: false,
                minutes: ['00', '30'],
                schedule: {
                    week: {
                        from: {
                            hour: '00',
                            minute: '00'
                        },
                        to: {
                            hour: '00',
                            minute: '00'
                        }
                    },
                    saturday: {
                        from: {
                            hour: '00',
                            minute: '00'
                        },
                        to: {
                            hour: '00',
                            minute: '00'
                        }
                    },
                    sunday: {
                        from: {
                            hour: '00',
                            minute: '00'
                        },
                        to: {
                            hour: '00',
                            minute: '00'
                        }
                    }
                },
            }
        },
        mounted() {
            this.loadingSchedule = true

            API.get('settings/schedule/department').then(res => {
                const data = res.data
                this.schedule = {
                    saturday: data.saturday,
                    week: data.week,
                    sunday: data.sunday,
                }
            }).finally(() => {
                this.loadingSchedule = false
            })
        },
        methods: {
            scheduleTaskSubmit() {
                this.loadingSchedule = true
                API.post('settings/schedule/department/save', this.schedule).then(() => {
                    this.$notify.success('settings.schedule.notify.SAVE')
                }).catch(() => {
                    this.$notify.error('settings.schedule.notify.error.SAVE')
                }).finally(() => {
                    this.loadingSchedule = false
                })
            },
        },
        computed: {
            hours() {
                const hours = []
                for(let i = 0; i < 24; ++i) {
                  hours.push(i < 10 ? '0' + i : i.toString())
                }
                return hours
            }
        }
    }
</script>